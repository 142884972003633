import wishes from "./wishes";
import "./Wishlist.css";

export default function Wishlist() {
  const listWishes = wishes.map((wish) => (
    <div className="wishClass">
      <div className="grid-container">
        <div className="wishItem1">
          <b>{wish.title}</b>
        </div>
        <div className="wishItem2">
          <img src={wish.imageUrl} alt="wish" className="imageClass"></img>
        </div>
        <div className="wishItem3">
          <p>{wish.description}</p>
        </div>
        <div className="wishItem4">
          <a href={wish.productUrl} target="_blank" rel="noreferrer">
            Gå til hjemmeside
          </a>
        </div>
        <div className="wishItem5">
          <p>Pris: {wish.price} kr</p>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="App">
      <h1 style={{ marginTop: "5%" }}>Ønskeliste for Jonas</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2%",
          marginBottom: "10%",
        }}
      >
        <ul>{listWishes}</ul>
      </div>
    </div>
  );
}
