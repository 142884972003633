import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Portfolio from "./components/Portfolio";
import Wishlist from "./components/Wishlist";

function App() {
  return (
    <body>
      <Router>
        <Routes>
          <Route path="/ønskeliste" element={<Wishlist />} />
          <Route path="/" element={<Portfolio />} />
        </Routes>
      </Router>
    </body>
  );
}

export default App;
