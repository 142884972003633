export interface IWish {
  title: string;
  description: string;
  price: number;
  imageUrl: string;
  productUrl: string;
}

const wishes: IWish[] = [
  {
    title: "iFixit Mako 64-bit Præcisionsskruetrækker-sæt",
    description: "iFixit Skruetrækker sæt med 64 bits",
    price: 249,
    imageUrl:
      "https://www.elgiganten.dk/image/dv_web_D180001002256986/32750/ifixit-mako-64-bit-pracisionsskruetrakkersat--pdp_zoom-3000--pdp_main-960.jpg",
    productUrl:
      "https://www.elgiganten.dk/product/mobil-tablet-smartwatch/mobiltilbehor/diverse-mobiltilbehor/ifixit-mako-64-bit-pracisionsskruetrakkersat/32750",
  },
  {
    title: "Stegepande - 5-ply",
    description:
      "Stegepande konstrueret i 5 lag metal for jævn varme og professionelle resultater - hele livet.",
    price: 799,
    imageUrl:
      "https://www.gastrotools.dk/cdn/shop/files/Stegepande24cm.jpg?v=1698535366",
    productUrl:
      "https://www.gastrotools.dk/products/stegepande-5ply?_pos=2&_fid=63601c610&_ss=c",
  },
  {
    title: "100% EXTRA FINE MERINO CREW NECK JUMPER",
    description: "Merino trøje i størrelse L, med farven Natural",
    price: 349,
    imageUrl:
      "https://image.uniqlo.com/UQ/ST3/eu/imagesgoods/460933/item/eugoods_30_460933.jpg?width=1083&impolicy=quality_60&imformat=chrome",
    productUrl:
      "https://www.uniqlo.com/dk/en/product/100pct-extra-fine-merino-crew-neck-jumper-460933.html?dwvar_460933_size=SMA005&dwvar_460933_color=COL30",
  },
  {
    title: "SUPER NON-IRON SLIM FIT SHIRT (REGULAR COLLAR)",
    description: "Hvid skjorte i størrelse L",
    price: 349,
    imageUrl:
      "https://image.uniqlo.com/UQ/ST3/WesternCommon/imagesgoods/462332/item/goods_00_462332.jpg?width=1083&impolicy=quality_60&imformat=chrome",
    productUrl:
      "https://www.uniqlo.com/dk/en/product/super-non-iron-slim-fit-shirt-regular-collar-462332.html?dwvar_462332_size=SMA005&dwvar_462332_color=COL00",
  },
  {
    title: "Maria Black - Selene Huggie ørering",
    description: "Ørering i sølv",
    price: 600,
    imageUrl:
      "https://vibholm.dk/cdn/shop/products/100990AG_1.jpg?v=1690902112",
    productUrl:
      "https://vibholm.dk/products/maria-black-selene-huggie-orering-1-stk-solv",
  },
  {
    title: "SOUFFLÉ YARN CABLE KNIT CREW NECK JUMPER",
    description: "Uldtrøje i Off White, størrelse L",
    price: 399,
    imageUrl:
      "https://image.uniqlo.com/UQ/ST3/eu/imagesgoods/460950/sub/eugoods_460950_sub7.jpg?width=1083&impolicy=quality_60&imformat=chrome",
    productUrl:
      "https://www.uniqlo.com/dk/en/product/soufflé-yarn-cable-knit-crew-neck-jumper-460950.html?dwvar_460950_color=COL01&cgid=IDmf-gifts",
  },
  {
    title: "DRY COLOUR CREW NECK SHORT SLEEVED T-SHIRT",
    description: "T-Shirt i hvid, størrelse L",
    price: 99,
    imageUrl:
      "https://image.uniqlo.com/UQ/ST3/eu/imagesgoods/461557/item/eugoods_00_461557.jpg?width=1083&impolicy=quality_60&imformat=chrome",
    productUrl:
      "https://www.uniqlo.com/dk/en/product/dry-colour-crew-neck-short-sleeved-t-shirt-461557.html?dwvar_461557_size=SMA005&dwvar_461557_color=COL00",
  },
  {
    title: "APPLE AIRPODS PRO (2. GENERATION) 2023",
    description: "Anden generation af Apple AirPods Pro",
    price: 1699,
    imageUrl:
      "https://media.power-cdn.net/images/h-eae8f9bf21a3e300b67ad7783548bcdc/products/2669641/2669641_7_1200x1200_t_g.webp",
    productUrl:
      "https://www.power.dk/tv-og-lyd/hovedtelefoner/true-wireless-hovedtelefoner/apple-airpods-pro-2-generation-2023/p-2669641/?utm_source=pricerunner&utm_medium=cpc&utm_campaign=pricerunner",
  },
  {
    title: "UNIKA-K.DK Kaffekop",
    description: "TASJA P KAFFEKOP - OFF WHITE OG LYSERØD",
    price: 280,
    imageUrl:
      "https://unika-k.dk/cdn/shop/files/image_2fa927b8-bec5-493b-9799-8dc76c669a16.jpg?v=1687082225&width=2200",
    productUrl:
      "https://unika-k.dk/products/tasja-p-kaffekop-off-white-og-lyserod",
  },
  {
    title: "UNIKA-K.DK Kaffekop",
    description: "TASJA P KAFFEKOP - OFF WHITE OG LYSEBLÅ",
    price: 280,
    imageUrl:
      "https://unika-k.dk/cdn/shop/files/image_fefcb2c3-a666-44eb-a2bf-84e8fb456149.jpg?v=1687082432&width=2200",
    productUrl:
      "https://unika-k.dk/products/tasja-p-kaffekop-off-white-og-lysebla",
  },
  {
    title: "Espresso cup cream speckled mat",
    description: "Espresso kop i creme med sorte prikker",
    price: 180,
    imageUrl:
      "https://images.squarespace-cdn.com/content/v1/601feba5e28bfa216246006e/1693051859813-HTD6FKVU7JL6OCBFXJQS/0B67B707-0634-49E8-94CE-EDE3282997C6",
    productUrl:
      "https://tasjapceramics.com/shop/p/product-info-nb733-9ztgk-jepte-mjwg3-2nh26-mrmsr-tjce8",
  },
  {
    title: "Espresso cup white",
    description: "Espresso kop i hvid",
    price: 180,
    imageUrl:
      "https://images.squarespace-cdn.com/content/v1/601feba5e28bfa216246006e/1693051876449-3XN45RJH8U1SJKMWRHNP/326B36F7-4683-4E18-A877-EA23C0715EB4?format=2500w",
    productUrl:
      "https://tasjapceramics.com/shop/p/product-info-nb733-9ztgk-jepte-mjwg3-2nh26-mrmsr",
  },
  {
    title: "Mareld Plade med rist, 44 x 31 cm, rustfrit stål",
    description: "Mareld Plade med rist, 44 x 31 cm, rustfrit stål",
    price: 239,
    imageUrl:
      "https://static.goshopping.dk/products/600/mareld-plade-med-rist-44-x-31-cm-rustfrit-staal-hoz20220511-02-56059-1.jpg",
    productUrl:
      "https://www.kitchenone.dk/produkt/mareld-plade-med-rist-rustfrit-staal-44-x-31-cm/",
  },
];

export default wishes;
