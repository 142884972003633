import { Socials } from "./Socials";
import logo from "../images/mig.webp";
// import { Link } from "react-router-dom";

export default function Portfolio() {
  return (
    <body>
      <div className="App">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
          <div style={{ width: "40%", textAlign: "left" }}>
            <h1>Full-stack web developer with DevOps experience</h1>
            <p>
              I am a full-stack developer with experience in C#, TypeScript and
              JavaScript. I have experience in building scalable, secure and
              reliable web applications using various frameworks and
              technologies. I enjoy solving complex problems and learning new
              skills. I am passionate about creating high-quality code that
              follows best practices and industry standards. I am always looking
              for new opportunities to grow as a developer.
            </p>
            <Socials />
          </div>
          <div style={{ width: "30%" }}>
            <img src={logo} alt="its me" className="mig" />
          </div>
        </div>
      </div>
    </body>
  );
}
