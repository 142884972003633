import './Socials.css';


export function Socials() {
    return (<div className='socialsClass'>
        <a href="https://www.linkedin.com/in/jonashoft/" target="_blank" rel="noopener noreferrer" className='socialIconClass'>
            <img src="https://cdn.worldvectorlogo.com/logos/linkedin-icon-2.svg" alt="LinkedIn" width="50" height="50" />
        </a>
        <a href="https://github.com/jonashoft" target="_blank" rel="noopener noreferrer" className='socialIconClass'>
            <img src="https://cdn.worldvectorlogo.com/logos/github-icon-1.svg" alt="Github" width="50" height="50" />
        </a>
        <a href="mailto:jonas@hoft.dk" className='socialIconClass'>
            <img src="https://cdn.worldvectorlogo.com/logos/mail-ios.svg" alt="Email" width="50" height="50" />
        </a>
    </div>)
}